import React from 'react';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

const initialState = {
  globalSnackbar: {
    display: false, mesage: '', severity: 'info', displayDuration: 2000,
  },
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
