import React, { useState } from 'react';
import {
  Box, Button, TextField,
} from '@mui/material';

function Allergies({ formik, person, index }) {
  const [showTextField, setShowTextField] = useState(false);

  const handleClick = () => {
    setShowTextField(!showTextField);
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 1,
      justifyContent: 'center',
      flexDirection: { xs: 'column', sm: 'row' },
    }}
    >
      <Button onClick={handleClick} variant="outlined" size="small">Allergies ?</Button>
      {showTextField && (
        <TextField
          size="small"
          id={`persons[${index}].allergies`}
          name={`persons[${index}].allergies`}
          label="Liste"
          value={person.allergies}
          onChange={formik.handleChange}
          error={!!formik.errors[`persons[${index}].allergies`]}
          helperText={formik.errors[`persons[${index}].allergies`] || ''}
        />
      )}
    </Box>
  );
}

export default Allergies;
