/* eslint-disable camelcase */
import React from 'react';
import {
  Box, Typography, Button,
} from '@mui/material';
// import SpotifyWebApi from 'spotify-web-api-js';
// import { httpsCallable } from 'firebase/functions';
import LaunchIcon from '@mui/icons-material/Launch';

import Suggestion from './Suggestion';

// const spotifyApi = new SpotifyWebApi();

function SpotifyPlaylist() {
  //   const setTokens = useCallback((tokens) => {
  //     const { newAccessToken, newExpiresIn, newRefreshToken } = tokens;

  //     setAccessToken(newAccessToken);
  //     const expiresInMs = newExpiresIn * 1000;
  //     const expirationDate = new Date(Date.now() + expiresInMs);
  //     setExpiresThe(expirationDate.toISOString());
  //     setRefreshToken(newRefreshToken);
  //     localStorage.setItem('pipoSptAccessToken', newAccessToken);
  //     localStorage.setItem('pipoSptExpiresThe', expirationDate.toISOString());
  //     localStorage.setItem('pipoSptRefreshToken', newRefreshToken);
  //     spotifyApi.setAccessToken(newAccessToken);
  //   }, []);

  //   const getRefreshToken = useCallback(async () => {
  //     console.log('accessToken: ', accessToken);
  //     console.log('refreshToken: ', refreshToken);
  //     console.log('expiresThe: ', expiresThe);
  //     const url = 'https://accounts.spotify.com/api/token';

  //     const payload = {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       body: new URLSearchParams({
  //         grant_type: 'refresh_token',
  //         refresh_token: refreshToken,
  //         client_id: clientId,
  //       }),
  //     };
  //     const body = await fetch(url, payload);
  //     const response = await body.json();
  //     return response;
  //   }, [refreshToken]);

  //   const handleTokenRefresh = useCallback(async () => {
  //     try {
  //       setLoading(true);
  //       const data = await getRefreshToken();
  //       console.log('DATA: ', data);
  //       const { access_token: newAccessToken, expires_in: newExpiresIn } = data.body;
  //     //   setTokens({ newAccessToken, newExpiresIn, newRefreshToken: refreshToken });
  //     } catch (error) {
  //       console.log('HERE: ', error);
  //       globalActions.setSnackbarMessage({
  //         message: 'Petit problème, réessaye plus tard',
  //         severity: 'error',
  //         duration: 3000,
  //       });
  //     } finally {
  //       setLoading(false);
  //       setShouldTryRefresh(false);
  //     }
  //   }, [getRefreshToken, globalActions]);

  //   useEffect(() => {
  //     const fetchAccessToken = async () => {
  //       try {
  //         setLoading(true);
  //         const result = await getSpotifyAccessToken();
  //         const {
  //           accessToken: newAccessToken,
  //           expiresIn: newExpiresIn, refreshToken: newRefreshToken,
  //         } = result.data;
  //         setTokens({ newAccessToken, newExpiresIn, newRefreshToken });
  //       } catch (error) {
  //         globalActions.setSnackbarMessage({
  //           message: 'Petit problème, réessaye plus tard',
  //           severity: 'error',
  //           duration: 3000,
  //         });
  //       } finally {
  //         setLoading(false);
  //         setShouldTryLogin(false);
  //       }
  //     };

  //     if (!accessToken && loading === false && shouldTryLogin) {
  //     //   console.log('FETCH');
  //       fetchAccessToken();
  //     } else if (new Date(expiresThe) <= new Date() && loading === false && shouldTryResfresh) {
  //     //   console.log('REFRESH');
  //       handleTokenRefresh();
  //     } else if (accessToken) {
  //     //   console.log('SET');
  //       spotifyApi.setAccessToken(accessToken);
  //     }
  //   }, [refreshToken, getSpotifyAccessToken, globalActions, accessToken, expiresThe,
  //     setTokens, handleTokenRefresh, loading, shouldTryLogin, shouldTryResfresh]);

  //   const handleSearch = async () => {
  //     try {
  //       setLoading(true);
  //       const data = await spotifyApi.searchTracks(query, { limit: 20 });
  //       setTracks(data.tracks.items);
  //     } catch (error) {
  //       globalActions.setSnackbarMessage({
  //         message: 'Petit problème, réessaye',
  //         severity: 'error',
  //         duration: 3000,
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  // const playlistId = '3AVunTQdwIazCIdo05RbRF';
  // try {
  //   setLoading(true);
  //   await spotifyApi.addTracksToPlaylist(playlistId, [track.uri]);
  //   globalActions.setSnackbarMessage({
  //     message: 'Musique ajoutée à la playlist !',
  //     severity: 'success',
  //     duration: 3000,
  //   });
  // } catch (error) {
  //   globalActions.setSnackbarMessage({
  //     message: 'Petit problème, réessaye',
  //     severity: 'error',
  //     duration: 3000,
  //   });
  // } finally {
  //   setLoading(false);
  // }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 3,
      mt: 15,
      mx: 1,
      mb: 12,
    }}
    >
      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', p: 1 }}>
        <Typography sx={{
          color: 'deepPrimary.main',
          typography: {
            xs: 'h5',
            md: 'h4',
          },
        }}
        >
          {'Ajoute les sons qui t\'ambiancent !'}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', maxWidth: '400px' }}>
        <iframe
          title="playlist"
          style={{ borderRadius: 12 }}
          src="https://open.spotify.com/embed/playlist/3AVunTQdwIazCIdo05RbRF?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        // eslint-disable-next-line max-len
        href="https://open.spotify.com/playlist/3AVunTQdwIazCIdo05RbRF?si=fde2014b31004a8b"
        target="_blank"
        endIcon={<LaunchIcon />}
      >
        vers la playlist Spotify
      </Button>
      <Suggestion />
      {/* <Paper
        elevation={0}
        sx={{
          maxHeight: 800,
          mt: 12,
          overflow: 'auto',
          width: '100%',
          maxWidth: 800,
          display: 'flex',
          flexDirection: 'column',
          p: 3,
          alignItems: 'center',
          gap: 3,
          mb: 6,
        }}
      >
        <TextField
          label="Rechercher un son"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          onClick={handleSearch}
        >
          Chercher
        </LoadingButton>

        <List>
          {tracks.map((track) => (
            <ListItem key={track.id} button onClick={() => handleAddTrack(track)}>
              <ListItemText primary={track.name} secondary={track.artists.map((artist) => artist.name).join(', ')} />
            </ListItem>
          ))}
        </List>
      </Paper> */}
    </Box>

  );
}

export default SpotifyPlaylist;
