import React from 'react';
import { Box, Typography } from '@mui/material';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import seaview from '../files/images/Popo-06.jpg';

function LocalizationPage({ address }) {
  return (
    <ParallaxProvider>
      <Box
        sx={{
          width: '100%',
          height: { xs: '900px', lg: '750px' },
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Parallax translateY={['-2000px', '2000px']}>
          <img
            src={seaview}
            alt="seaview"
            style={{
              width: '100%', height: window.innerHeight, objectFit: 'cover', objectPosition: 'center',
            }}
          />
        </Parallax>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}
        />
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontSize: '24px',
          display: 'flex',
          flexDirection: 'column',
          mt: 3,
          mb: 3,
          px: 6,
          gap: 6,
          width: '100%',
        }}
        >

          <Typography sx={{
            typography: {
              xs: 'h3',
              md: 'h2',
            },
          }}
          >
            LOGEMENTS
          </Typography>
          <Box sx={{
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 3, justifyContent: 'space-around',
          }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              maxWidth: 600,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
            >
              <Typography variant="body1" sx={{ color: 'deepPrimary.main' }}>
                {'Le manoir est pourvu d\'un certains nombre de chambres, ainsi qu\'un dortoir. '}
                {'Merci de nous contacter au plus tôt si vous souhaitez réserver une place au dortoir.\n\n'}
                Pour les plus téméraires, il est possible de planter une tente dans le jardin !
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
            }}
            >
              <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <iframe
                  title="manoir-des-indes-map"
            // eslint-disable-next-line max-len
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2669.5035488133626!2d-4.158461222399692!3d48.003980671229044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4810d5598fb29093%3A0x4e2370e0e995a56f!2sManoir%20des%20Indes%2C%20The%20Originals%20Relais%20(Relais%20du%20Silence)!5e0!3m2!1sfr!2sfr!4v1721543480281!5m2!1sfr!2sfr"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </Box>

              <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', p: 1 }}>
                <Typography variant="body1" sx={{ color: 'deepPrimary.main' }}>
                  {'1, allée de Prad ar C\'Hras\n29000 Quimper'}
                </Typography>
              </Box>

            </Box>
          </Box>
        </Box>
      </Box>
    </ParallaxProvider>
  );
}

export default LocalizationPage;
