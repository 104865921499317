/* eslint-disable camelcase */
import React from 'react';
import SpotifyPlaylist from './SpotifyPlaylist';
// import SoundcloudPlaylist from './SoundcloudPlaylist';

function Playlist() {
  return <SpotifyPlaylist />;
}

export default Playlist;
