import React, { useState } from 'react';
import {
  Box, Typography, TextField, Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useFirestore } from 'reactfire';
import { collection, addDoc } from 'firebase/firestore';
import useGlobal from '../../global-state/store';
import catjam from '../../files/images/catjam.gif';
import catjam2 from '../../files/images/catjam2.gif';
import catjam3 from '../../files/images/catjam3.gif';
import catjam4 from '../../files/images/catjam4.gif';
import hobbiton from '../../files/images/hobbiton.jpg';

export default function Suggestion() {
  const [loading, setLoading] = useState(false);
  const [suggestedTrack, setSuggestedTrack] = useState('');

  const [, globalActions] = useGlobal();
  const firestore = useFirestore();

  const successMessages = [
    'Merci !',
    'C\'est comme si c\'était fait!',
    'Merci bro\' / sis\' !',
    'Gracias, amigo o amiga !',
    'Danke, mein Freund!',
    'Grazie mille!',
    'ありがとう、仲間！',
    'Obrigado, parceiro!',
    'Merci 1000 fois ! Non ! 2000 fois même !',
  ];

  const getRandomSuccessMessage = () => {
    const randomIndex = Math.floor(Math.random() * successMessages.length);
    return successMessages[randomIndex];
  };

  const handleAddTrack = async (track) => {
    try {
      setLoading(true);
      const trackToSave = {
        trackId: track.trackId,
        trackName: track.trackName,
        artistNames: track.artistNames.map((artist) => artist.name),
        addedAt: new Date(),
      };
      await addDoc(collection(firestore, 'spotifyPlaylist'), trackToSave);
      globalActions.setSnackbarMessage({
        message: getRandomSuccessMessage(),
        severity: 'success',
        duration: 3000,
      });
    } catch (error) {
      globalActions.setSnackbarMessage({
        message: 'Petit problème, réessaye plus tard',
        severity: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestTrack = () => {
    if (suggestedTrack.trim() !== '') {
      const track = {
        trackId: `suggested-${Date.now()}`,
        trackName: suggestedTrack,
        artistNames: [],
        addedAt: new Date(),
      };
      handleAddTrack(track);
      setSuggestedTrack('');
    }
  };

  return (
    <Box sx={{
      position: 'relative',
      mt: 3,
      p: 3,
      width: '100%',
    }}
    >
      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: -1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      }}
      >
        <img
          src={hobbiton}
          alt="Hobbiton"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'fill',
          }}
        />
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 10,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      }}
      >
        <img
          src={catjam}
          alt="Catjam"
          style={{
            width: '100%',
            maxWidth: '1000px',
            height: '100%',
            objectFit: 'fill',
          }}
        />
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <img
          src={catjam3}
          alt="Catjam3"
          style={{
            width: '100px', height: 'auto', objectFit: 'fill',
          }}
        />
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 0,
        right: 100,
        zIndex: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <img
          src={catjam2}
          alt="Catjam2"
          style={{
            width: '100px', height: 'auto', objectFit: 'fill',
          }}
        />
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 80,
        right: 0,
        zIndex: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <img
          src={catjam4}
          alt="Catjam4"
          style={{
            width: '100px', height: 'auto', objectFit: 'fill',
          }}
        />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        zIndex: 100,
        position: 'relative',
        py: 6,
        pb: 8,
      }}
      >
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', p: 1 }}>
          <Typography variant="h5">
            Suggère une musique que nous ajouterons pour toi :
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
        }}
        >
          <TextField
            label="Nom, Artiste"
            variant="outlined"
            value={suggestedTrack}
            onChange={(e) => setSuggestedTrack(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSuggestTrack}
            endIcon={<SendIcon />}
          >
            Envoyer
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
