import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Footer from './Footer';
import Pipo from '../files/icons/Pipo';

export default function LoadingPage() {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Pipo width="200" />
      <CircularProgress
        color="inherit"
        sx={{
          width: {
            xs: '30px', md: '60px',
          },
          height: {
            xs: '30px', md: '60px',
          },
        }}
      />
      <Footer size="small" />
    </Box>
  );
}
