import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';

function Footer({ size = 'regular' }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 3,
        p: 3,
        gap: { xs: 0, md: 1 },
        color: 'deepPrimary.main',
      }}
    >
      <Typography variant={size === 'small' ? 'caption' : 'body1'}>Des questions ? Contacte nous</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        mt: 3,
        gap: { xs: 0, md: 1 },
      }}
      >
        <Typography variant={size === 'small' ? 'caption' : 'body2'}>+33 6 08 90 89 14</Typography>
        {isLargeScreen && (<Typography variant="body2">-</Typography>)}
        <Typography variant={size === 'small' ? 'caption' : 'body2'}>chans.pierre@gmail.com</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        mt: { xs: 1, md: 3 },
        gap: { xs: 0, md: 1 },
      }}
      >
        <Typography variant={size === 'small' ? 'caption' : 'body2'}>+33 6 83 72 45 66</Typography>
        {isLargeScreen && (<Typography variant="body2">-</Typography>)}
        <Typography variant={size === 'small' ? 'caption' : 'body2'}>pauline.hyy@gmail.com</Typography>
      </Box>
    </Box>
  );
}

export default Footer;
