import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import bgImage from '../files/images/anda-beach.jpg';
import Counter from './Counter';
import Pipoheart from '../files/icons/Pipoheart';
import Pach from '../files/icons/Pach';
import LoopDown from '../files/svg/loop-down.svg';

export default function Header({ contactSection }) {
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 30,
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: { xs: '675px', lg: '750px' } }}>
      <img src={bgImage} alt="bg" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
      >
        <Typography
          sx={{
            typography: {
              xs: 'h5',
              md: 'h4',
            },
          }}
        >
          Save the date
          {' '}
          <Pipoheart size={24} />
        </Typography>
        <Typography
          sx={{
            typography: {
              xs: 'h2',
              md: 'h1',
            },
            fontFamily: 'MyCustomFont, sans-serif',
            mb: 6,
            color: '#FFFFFF',
          }}
        >
          PAULINE
          {' '}
          <Box component="span" sx={{ fontSize: { xs: '30px', md: '70px' } }}>&</Box>
          {' '}
          PIERRE
        </Typography>
        <Typography
          sx={{
            typography: {
              xs: 'h4',
              md: 'h3',
            },
            mb: 6,
            fontWeight: 'bold',
            color: '#FFFFFF',
          }}
        >
          12 . 07 . 2025
        </Typography>
        <Counter />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          zIndex: 1000,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => scrollToRef(contactSection)}
      >
        <Pach width="40" />
        <img src={LoopDown} alt="loop-down" style={{ width: 60, height: 60 }} />
      </Box>
    </Box>
  );
}
