import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { ParallaxProvider } from 'react-scroll-parallax';
import hug from '../files/images/Popo-65.jpg';
import WeddingDetails from './WeddingDetails';
import RSVP from './rsvp/RSVP';
import Footer from './Footer';
import LocalizationLink from './LocalizationLink';
import Background from './Background';
import Header from './Header';

function Home({ rsvpSection, contactSection }) {
  return (
    <ParallaxProvider>
      <>
        <Background refToSwitch={rsvpSection} />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Header contactSection={contactSection} />
          <Box sx={{
            py: 12,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            backgroundColor: 'terracota2.main',
            px: 1,
          }}
          >
            <Typography
              sx={{
                typography: {
                  xs: 'h5',
                  sm: 'h4',
                  md: 'h3',
                },
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              NOUS ALLONS NOUS MARIER !
            </Typography>
          </Box>

          <WeddingDetails />

          <Box
            sx={{
              width: '100%',
              height: '450px',
              overflow: 'hidden',
              position: 'relative',
            }}
          />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 12,
            backgroundColor: 'background.default',
          }}
          >
            <RSVP rsvpSection={rsvpSection} />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '450px',
              overflow: 'hidden',
              position: 'relative',
            }}
          />

          <LocalizationLink />
          <div style={{ position: 'relative', width: '100%', height: '750px' }} ref={contactSection}>
            <img src={hug} alt="bg" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            <Box
              sx={{
                position: 'absolute',
                top: '75%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
              }}
            >
              <Footer />
            </Box>
          </div>
        </Box>
      </>
    </ParallaxProvider>
  );
}

export default Home;
