import './App.css';
import React from 'react';
import {
  CssBaseline, StyledEngineProvider, createTheme,
} from '@mui/material';
import {
  AnalyticsProvider, AuthProvider, FirebaseAppProvider, FirestoreProvider, FunctionsProvider,
  RemoteConfigProvider, StorageProvider, useFirebaseApp, useInitFirestore, useInitRemoteConfig,
} from 'reactfire';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { initializeFirestore } from 'firebase/firestore';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { ThemeProvider } from '@emotion/react';
import LilitaOne from './files/fonts/LilitaOne-Regular.ttf';
import LoadingPage from './components/LoadingPage';
import Router from './components/Router';

const firebaseConfig = {
  apiKey: 'AIzaSyAiuUosjtjCYqBISsqx6PMDo--x3HLQXfU',
  authDomain: 'pipo-a2146.firebaseapp.com',
  projectId: 'pipo-a2146',
  storageBucket: 'pipo-a2146.appspot.com',
  messagingSenderId: '611336889029',
  appId: '1:611336889029:web:40f81768695723b01b06ac',
};

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${LilitaOne}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, 
          U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#f8f5f1',
      // default: '#ffffff',
      dark: '#334654',
      // dark: '#d7ac95',

    },
    primary: {
      main: '#334654',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      // primary: '#334654',
      primary: '#3a4049',
      // primary: '#214c4f',
      // primary: '#d6bb6e',
      // primary: '#07305A',
    },
    deepPrimary: {
      main: '#2b4f5a',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#e0b45a',
      contrastText: 'white',
    },
    gold2: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
    white: {
      main: '#FFFFFF',
      contrastText: 'black',
    },
    majorelle: {
      main: '#07305a',
      contrastText: 'white',
    },
    terracota: {
      main: '#d37447',
      contrastText: 'white',
    },
    terracotalight: {
      main: '#dea088',
      contrastText: 'white',
    },
    terrapeauta: {
      main: '#d7ac95',
      contrastText: 'white',
    },
    terracota2: {
      main: '#bb6c4e',
      contrastText: 'white',
    },
    fougere: {
      main: '#b9a5a7',
      contrastText: 'white',
    },
    paper1: {
      main: '#f8f5f1',
      contrastText: '#334654',
    },
  },
});

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const functionsInstance = getFunctions(app);
  const storageInstance = getStorage(app);
  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
    connectStorageEmulator(storageInstance, 'localhost', 9199);
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functionsInstance}>
          <StorageProvider sdk={storageInstance}>
            <ProvidersWithLoading />
          </StorageProvider>
        </FunctionsProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function ProvidersWithLoading(props) {
  const { status: firestoreStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const savedSetting = localStorage.getItem('forceLongPolling');
    const forceLongPolling = savedSetting === 'true';
    const baseSettings = {};

    if (forceLongPolling) {
      baseSettings.experimentalForceLongPolling = true;
    } else {
      baseSettings.experimentalAutoDetectLongPolling = true;
    }
    const fsSettings = (process.env.NODE_ENV === 'development')
      ? {
        ...baseSettings,
        host: 'localhost:4401',
        ssl: false,
      } : baseSettings;

    const db = initializeFirestore(firebaseApp, fsSettings);
    return db;
  });

  const { status: remoteConfigStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 600000,
      fetchTimeoutMillis: 600000,
    };

    try {
      await fetchAndActivate(remoteConfig);
    } catch (e) {
      console.warn('Remote config failed to be activated');
    }

    return remoteConfig;
  });

  if (firestoreStatus === 'loading' || remoteConfigStatus === 'loading') {
  // if (true) {
    return (
      <LoadingPage />
    );
  }

  return (

    <FirestoreProvider sdk={firestoreInstance}>
      <RemoteConfigProvider sdk={remoteConfigInstance}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router />
          </ThemeProvider>
        </StyledEngineProvider>
      </RemoteConfigProvider>
    </FirestoreProvider>

  );
}

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>

      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>

    </FirebaseAppProvider>
  );
}

export default App;
