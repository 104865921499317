import React from 'react';
import {
  Box, Button, TextField, Select, MenuItem,
  Switch, IconButton, Typography,
  FormControl, InputLabel, FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import { useFirestore } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';
import {
  collection, doc, writeBatch,
} from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import useGlobal from '../../global-state/store';
import Allergies from './Allergies';

function RSVP({ rsvpSection }) {
  const firestore = useFirestore();
  const [, globalActions] = useGlobal();

  const successMessages = [
    'Merci pour votre réponse!',
    'Votre réponse a été enregistrée avec succès!',
    'Nous avons bien reçu votre réponse, merci!',
    'Merci bro\' / sis\' !',
    'Gracias, amigo o amiga !',
    'Danke, mein Freund!',
    'Grazie mille!',
    'ありがとう、仲間！',
    'Obrigado, parceiro!',
    'Merci 1000 fois ! Non ! 2000 fois même !',
  ];

  const getRandomSuccessMessage = () => {
    const randomIndex = Math.floor(Math.random() * successMessages.length);
    return successMessages[randomIndex];
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const formik = useFormik({
    initialValues: {
      persons: [
        {
          id: uuidv4(),
          firstName: '',
          lastName: '',
          meal: 'adulte',
          allergies: '',
          note: '',
        },
      ],
      afterPartyPresence: false,
      nannies: false,
      note: '',
    },
    onSubmit: (values) => {
      console.log(values);
      const errors = {};
      const filteredPersons = values.persons.filter((person, index) => {
        if (person.firstName && !person.lastName) {
          errors[`persons[${index}].lastName`] = 'Nom requis';
          return true;
        } if (!person.firstName && person.lastName) {
          errors[`persons[${index}].firstName`] = 'Prénom requis';
          return true;
        }
        return person.firstName || person.lastName;
      });

      if (Object.keys(errors).length === 0 && filteredPersons.length > 0) {
        const batch = writeBatch(firestore);

        const batchId = uuidv4();

        filteredPersons.forEach((person, index) => {
          const docRef = doc(collection(firestore, 'rsvp'));
          const basePersonToSet = {
            ...person,
            batchId,
            timestamp: new Date(),
            nannies: formik.values.nannies,
            afterPartyPresence: formik.values.afterPartyPresence,
          };
          if (index === 0) {
            batch.set(docRef, {
              ...basePersonToSet,
              note: formik.values.note,
            });
          } else {
            batch.set(docRef, {
              ...basePersonToSet,
            });
          }
        });
        batch.commit()
          .then(() => {
            const newInitialValues = {
              ...formik.initialValues,
              persons: [
                {
                  id: uuidv4(),
                  firstName: '',
                  lastName: '',
                  meal: 'adulte',
                  allergies: '',
                  note: '',
                },
              ],
            };
            formik.resetForm({ values: newInitialValues });
            globalActions.setSnackbarMessage({
              message: getRandomSuccessMessage(),
              severity: 'success',
              duration: 8000,
            });
            scrollToTop();
          })
          .catch((error) => {
            console.error('Error writing document: ', error);
            globalActions.setSnackbarMessage({
              message: 'Petite erreur apparemment... Réessaye ou contacte nous directement, merci !',
              severity: 'error',
              duration: 8000,
            });
          });
      } else {
        formik.setErrors(errors);
      }
    },
  });

  const handleAddPerson = () => {
    if (formik.values.persons.length < 10) {
      const newPersons = [...formik.values.persons, {
        id: uuidv4(), firstName: '', lastName: '', meal: 'adulte', allergies: '', note: '',
      }];
      formik.setFieldValue('persons', newPersons);
    }
  };

  const handleRemovePerson = (index) => {
    const currentPersons = formik.values.persons;
    const updatedPersons = currentPersons.filter((_, i) => i !== index);
    formik.setFieldValue('persons', updatedPersons);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      ref={rsvpSection}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 3,
        alignItems: 'center',
        maxWidth: 800,
        minHeight: '95vh',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="h2"
        sx={{
          mb: 6,
          typography: {
            xs: 'h4',
            md: 'h3',
          },
        }}
      >
        CONFIRMEZ VOTRE PRÉSENCE :
      </Typography>
      {formik.values.persons.map((person, index) => (
        <Box
          key={person.id || index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextField
                sx={{ flexGrow: 1 }}
                id={`persons[${index}].firstName`}
                name={`persons[${index}].firstName`}
                label="Prénom"
                value={person.firstName}
                onChange={formik.handleChange}
                error={!!formik.errors[`persons[${index}].firstName`]}
                helperText={formik.errors[`persons[${index}].firstName`] || ''}
              />
              <TextField
                sx={{ flexGrow: 1 }}
                id={`persons[${index}].lastName`}
                name={`persons[${index}].lastName`}
                label="Nom"
                value={person.lastName}
                onChange={formik.handleChange}
                error={!!formik.errors[`persons[${index}].lastName`]}
                helperText={formik.errors[`persons[${index}].lastName`] || ''}
              />
              <FormControl sx={{ flexGrow: 1, minWidth: '183px' }}>
                <InputLabel id={`person-meal-label-${index}`}>Repas</InputLabel>
                <Select
                  labelId={`person-meal-label-${index}`}
                  id={`persons[${index}].meal`}
                  name={`persons[${index}].meal`}
                  value={person.meal}
                  label="Repas"
                  onChange={formik.handleChange}
                >
                  <MenuItem value="adulte">Adulte</MenuItem>
                  <MenuItem value="vegetarian">Adulte - Végétarien</MenuItem>
                  <MenuItem value="enfant">Enfant</MenuItem>
                  <MenuItem value="bebe">Bébé (pas de repas)</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <IconButton
              onClick={() => handleRemovePerson(index)}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Box>

          <Allergies formik={formik} person={person} index={index} />

        </Box>
      ))}
      <Button
        sx={{ mt: 6 }}
        variant="outlined"
        onClick={handleAddPerson}
        disabled={formik.values.persons.length >= 10}
        endIcon={<AddIcon />}
      >
        Ajouter une personne
      </Button>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          display: 'flex', alignItems: 'center', gap: 1, mb: 6,
        }}
      >
        <InfoIcon fontSize="small" />
        - de 12 ans = repas enfant
      </Typography>

      <FormControlLabel
        id="afterPartyPresence"
        name="afterPartyPresence"
        value={formik.values.afterPartyPresence}
        control={(
          <Switch
            color="primary"
            checked={formik.values.afterPartyPresence}
            onChange={formik.handleChange}
          />
)}
        label="Serez-vous présent le lendemain ?"
        labelPlacement="start"
      />
      <FormControlLabel
        id="nannies"
        name="nannies"
        value={formik.values.nannies}
        control={(
          <Switch
            color="primary"
            checked={formik.values.nannies}
            onChange={formik.handleChange}
          />
)}
        label="Nous prévoyons d’engager des nounous. Seriez-vous intéressé par leurs services ?"
        labelPlacement="start"
      />

      <TextField
        id="note"
        name="note"
        label="Note, commentaire"
        multiline
        rows={4} // Adjust the number of rows as needed
        variant="outlined"
        value={formik.values.note}
        onChange={formik.handleChange}
        inputProps={{
          maxLength: 200,
        }}
        helperText={`${formik.values.note.length}/200`}
        fullWidth
      />

      <Button
        variant="contained"
        type="submit"
        endIcon={<SendIcon />}
      >
        Envoyer
      </Button>
    </Box>
  );
}
export default RSVP;
