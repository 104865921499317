import React from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { useFirestoreCollectionData, useFirestore } from 'reactfire';
import { collection, query, orderBy } from 'firebase/firestore';

function getRandomLightColor() {
  const r = Math.floor(Math.random() * 128 + 128);
  const g = Math.floor(Math.random() * 128 + 128);
  const b = Math.floor(Math.random() * 128 + 128);
  return `rgb(${r}, ${g}, ${b})`;
}

export default function List() {
  const firestore = useFirestore();

  const personsRef = collection(firestore, 'rsvp');
  const personsQuery = query(personsRef, orderBy('timestamp', 'desc'));

  const { data: persons } = useFirestoreCollectionData(personsQuery, { idField: 'fsID' });

  const batchIdToColorMap = {};
  (persons || []).forEach((person) => {
    if (!batchIdToColorMap[person.batchId]) {
      batchIdToColorMap[person.batchId] = getRandomLightColor();
    }
  });

  const columns: GridColDef[] = [
    { field: 'lastName', headerName: 'Nom', width: 150 },
    { field: 'firstName', headerName: 'Prénom', width: 150 },
    { field: 'meal', headerName: 'Repas', width: 150 },
    { field: 'allergies', headerName: 'Allergies', width: 150 },
    {
      field: 'afterPartyPresence', headerName: 'Lendemain', width: 100, type: 'boolean',
    },
    {
      field: 'nannies', headerName: 'Nounous', width: 100, type: 'boolean',
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 300,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: 10,
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'timestamp',
      headerName: 'Date ajout',
      width: 175,
      type: 'dateTime',
      valueGetter: (value, row, column, apiRef) => row.timestamp.toDate(),
    },
    { field: 'batchId', headerName: 'Groupe ID', width: 150 },
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'fsID', headerName: 'fsID', width: 150 },
  ];

  const getRowClassName = (params) => `batch-${params.row.batchId}`;

  const sxStyles = {};
  Object.keys(batchIdToColorMap).forEach((batchId) => {
    sxStyles[`& .batch-${batchId}`] = {
      backgroundColor: batchIdToColorMap[batchId],
      '&:hover': {
        backgroundColor: batchIdToColorMap[batchId],
      },
      '&.Mui-selected': {
        backgroundColor: batchIdToColorMap[batchId],
        '&:hover': {
          backgroundColor: batchIdToColorMap[batchId],
        },
      },
    };
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 3,
      width: '100%',
      textAlign: 'left',
      mt: 12,
    }}
    >
      <Box sx={{
        display: 'flex', flexDirection: 'column', height: 1000, gap: 3, width: '95%',
      }}
      >

        <Typography variant="h4">Les invités</Typography>
        <DataGrid
          rows={persons || []}
          columns={columns}
          getRowClassName={getRowClassName}
          sx={sxStyles}
          slots={{ toolbar: GridToolbar }}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}
