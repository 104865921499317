import React from 'react';
import {
  Button,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ReturnHomeButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate('/');
  };

  if (location.pathname === '/') {
    return null;
  }

  return (
    <Button
      sx={{
        position: 'fixed', top: 20, left: 30, zIndex: 1000,
      }}
      onClick={handleClick}
      startIcon={<ArrowBackIosIcon />}
    >
      Retour
    </Button>
  );
}
