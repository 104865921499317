/* eslint-disable max-len */
import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';

function Pipo(props) {
  const { width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="100%"
      fill="none"
      viewBox="0 0 784 911"
      overflow="visible"
    >
      <path d="M567 242.5C567 242.5 424.905 273.332 409 486.485C409 486.485 603.157 549.739 743 454.108C743 454.108 702.5 294 621.5 262L567 242.5Z" fill="#F7F2E8" />
      <path d="M606.869 429.914C625.703 424.645 641.505 415.474 652.019 403.908C658.384 403.825 666.251 397.006 671.071 386.735C676.812 374.493 676.038 362.029 669.346 358.902C667.809 358.175 666.08 358.036 664.272 358.361C655.837 328.561 616.811 306.028 569.905 306.028C528.022 306.028 492.425 324.004 479.269 349.076C476.948 347.216 474.425 346.302 471.91 346.676C464.599 347.749 460.217 359.108 462.132 372.038C464.035 384.971 471.497 394.583 478.816 393.506C479.229 393.442 479.63 393.339 480.028 393.216C489.083 409.264 507.444 422.221 530.68 429.21L406.179 640.812L531.999 471.189L600.476 463.242L705.508 624.918L606.869 429.914Z" fill="#E7B985" />
      <path d="M542.31 561.309L528.03 463.238C528.03 463.238 554.477 446.335 564.052 446.165C574.677 445.978 604.454 463.238 604.454 463.238L588.163 560.86L542.31 561.309Z" fill="#F9EDDC" />
      <path d="M580.769 238.056L573.726 237.687C524.33 235.099 482.309 270.739 479.869 317.293L473.678 435.435C472.676 454.557 483.856 475.759 494.5 490.5C512.5 460 517 447 540 447L541.017 425.035C513.976 416.098 495.173 391.17 496.656 362.869L498.534 327.047L555 330L569.5 308.5L580 332L646.014 334.776L644.136 370.598C642.653 398.899 621.345 421.727 593.523 427.786L593 447.863C619.5 449 624 461.5 639.5 495C651.724 481.425 658.591 464.389 659.597 445.179L665.789 327.037C668.229 280.482 630.163 240.645 580.769 238.056Z" fill="#351F10" />
      <path d="M527.931 370.234C532.625 370.234 536.43 366.11 536.43 361.023C536.43 355.937 532.625 351.813 527.931 351.813C523.237 351.813 519.432 355.937 519.432 361.023C519.432 366.11 523.237 370.234 527.931 370.234Z" fill="#351F10" />
      <path d="M600.023 374.334C604.715 374.334 608.518 370.211 608.518 365.124C608.518 360.037 604.715 355.914 600.023 355.914C595.332 355.914 591.528 360.037 591.528 365.124C591.528 370.211 595.332 374.334 600.023 374.334Z" fill="#351F10" />
      <path d="M636.956 271.555C641.498 268.635 655.504 257.196 647.875 251.235C641.891 246.551 626.276 259.667 627.122 264.82C627.229 258.153 624.933 242.887 615.846 248.045C609.445 251.677 612.369 269.243 616.474 274.468C611.872 267.022 593.71 267.828 592.22 275.548C589.645 288.994 610.335 289.519 618.488 284.779C613.001 291.259 604.744 301.979 612.691 307.629C613.466 308.182 621.452 308.233 622.446 307.705C625.012 306.334 627.321 302.357 628.29 298.634C629.041 304.808 631.866 311.897 637.473 311.257C648.872 309.958 644.8 288.386 640.576 283.372C646.17 288.478 656.616 287.786 658.186 280.567C660.868 268.226 644.696 266.247 636.956 271.555Z" fill="#F9EDDC" />
      <path d="M382.347 873.917C382.347 873.917 431.338 531.739 580.105 558.921C728.871 586.095 777.036 855.373 777.036 855.373C777.036 855.373 626.045 958.68 382.347 873.917Z" fill="#FFF7E9" />
      <path d="M761.675 778.655C761.675 778.655 725.299 542.598 568.92 558.972C569.015 558.11 569.055 557.641 569.055 557.641C410.121 555.654 398.2 796.043 398.2 796.043C535.734 746.67 563.719 597.16 568.272 564.153C575.257 593.477 617.645 743.197 761.675 778.655Z" fill="#F9EDDC" />
      <path fillRule="evenodd" clipRule="evenodd" d="M203.096 419.643C265.018 419.643 315.522 380.711 315.522 332.983C315.522 285.255 265.018 246.327 203.096 246.327C141.174 246.327 90.6736 285.25 90.6736 332.983C90.6778 380.711 141.178 419.643 203.096 419.643Z" fill="#FFD8B8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M274.931 270.885C227.445 257.619 111.653 233.203 110.248 340.976C110.194 345.149 95.3434 314.57 92.4419 317.974C102.12 266.683 165.665 237.894 219.698 245.353C239.649 248.104 257.86 253.346 274.931 270.885Z" fill="#C9B5A6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M310.564 310.319C243.825 236.159 127.239 326.327 120.976 208.077C149.807 236.924 160 245.002 200.826 243.793C230.167 242.928 294.087 246.791 310.564 310.319Z" fill="#B28C62" />
      <path fillRule="evenodd" clipRule="evenodd" d="M311 324C309.65 319.61 296.604 351.328 296.132 334.835C293.009 226.426 125.868 302.04 153.436 183C173.634 218.432 177.564 232.71 217.699 240.9C258.592 249.241 314.5 269 311 324Z" fill="#C9B5A6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M285.5 268C279.5 272 275.5 280 269.5 283.5C243 267.5 126 268 153.569 183.5C173.767 218.932 177.864 232.81 218 241C230.167 243.483 265 249 285.5 268Z" fill="#C69C6D" />
      <path d="M107.462 314.113C104.46 301.154 95.3043 292.206 87.0129 294.126C78.7215 296.047 74.4339 308.11 77.4362 321.069C80.4385 334.028 89.5938 342.976 97.8852 341.055C106.177 339.134 110.464 327.072 107.462 314.113Z" fill="#FFD8B8" />
      <path d="M332.496 331.191C335.498 318.232 331.211 306.169 322.919 304.249C314.628 302.328 305.472 311.276 302.47 324.235C299.468 337.194 303.755 349.256 312.047 351.177C320.338 353.098 329.494 344.15 332.496 331.191Z" fill="#FFD8B8" />
      <path d="M229.033 361H176.458C174.549 361 173 362.552 173 364.466V370.013C173 380.719 181.689 389.426 192.368 389.426H213.121C223.801 389.426 232.489 380.719 232.489 370.013V364.466C232.491 362.553 230.943 361 229.033 361Z" fill="white" />
      <path d="M167.258 338.395C162.697 338.395 159 334.697 159 330.136V318.259C159 313.698 162.697 310 167.258 310C171.819 310 175.517 313.698 175.517 318.259V330.136C175.518 334.697 171.82 338.395 167.258 338.395Z" fill="#351F10" />
      <path d="M239.259 338.395C234.697 338.395 231 334.697 231 330.136V318.259C231 313.698 234.697 310 239.259 310C243.819 310 247.517 313.698 247.517 318.259V330.136C247.516 334.697 243.819 338.395 239.259 338.395Z" fill="#351F10" />
      <path d="M306.905 674.47L284.916 531.731L408.837 635.511L287.252 437.014L130.702 442.14L0.904297 664.648L130.042 528.481L105.261 670.254L132.335 670.759L161.062 874.735L112.154 911L186.325 907.026V868.664C186.325 868.664 210.284 682.659 210.284 687.574C210.284 692.485 210.284 905.834 210.284 905.834L293.8 904.893L244.602 875.244L287.928 673.623L306.905 674.47Z" fill="#351F10" />
      <path d="M155.893 423.123L216.308 607.193L254.146 419.849L155.893 423.123Z" fill="#E2D4BF" />
      <path d="M159.278 422.277L118.412 443.109C118.412 443.109 152.385 572.748 214.615 605.5L159.278 422.277Z" fill="#665E5A" />
      <path d="M243.478 408.442L216.308 607.193C216.308 607.193 278.825 513.986 295.199 437.018L243.478 408.442Z" fill="#665E5A" />
      <path d="M205.505 417.421L150.466 390.466L156.231 460.568L206.339 430.74L258.604 460.64L257.754 390.832L205.505 417.421Z" fill="white" />
      <path d="M713.57 663.821C713.57 663.821 724.365 701.282 744.053 716.524L754.841 710.806C754.841 710.806 719.291 670.81 717.38 658.108L713.57 663.821Z" fill="#FFF7E9" />
      <path d="M706.585 657.468L700.235 651.754C698.332 651.754 678.644 715.884 713.566 752.709L731.982 741.278C731.982 741.278 698.336 696.2 706.585 657.468Z" fill="#FFF7E9" />
      <path d="M772.983 593.445C787.478 581.907 759.494 578.815 751.96 579.777C756.152 579.24 753.883 567.483 751.706 565.759C746.803 561.877 737.839 565.636 733.421 568.365C736.011 561.988 736.361 554.47 727.663 554.252C718.862 554.037 712.521 563.355 708.69 569.994C708.917 563.943 707.403 555.094 699.353 556.509C690.469 558.082 690.218 569.82 691.601 576.499C689.817 570.781 686.138 562.477 679.129 567.384C671.949 572.418 675.505 583.369 678.561 589.535C674.067 587.465 665.079 583.214 660.82 587.795C656.592 592.349 661.602 599.914 664.622 603.657C656.349 609.954 652.165 620.452 664.042 625.081C659.806 628.784 656.437 634.84 661.153 639.604C666.525 645.031 676.037 641.269 682.323 639.957C675.588 644.761 670.999 655.827 681.207 659.617C688.176 662.204 697.271 658.775 702.393 654.011C699.445 659.133 696.472 671.196 705.007 671.375C710.935 671.498 716.236 664.85 719.987 661.103C718.465 666.583 718.107 677.184 725.995 677.811C733.337 678.4 737.831 667.393 739.949 662.001C752.35 674.569 757.758 658.274 760.026 646.903C762.935 656.272 773.671 652.434 777.378 645.611C782.313 636.564 775.189 624.159 770.945 616.177C776.969 618.914 781.888 617.679 782.945 610.67C784.065 603.212 778.757 597.208 772.983 593.445Z" fill="#FFF7E9" />
      <path d="M466.947 537.536L406.183 640.812L479.237 542.324C475.16 540.659 471.064 539.058 466.947 537.536Z" fill="#FFF7E9" />
      <path d="M649.138 538.132L705.516 624.914L658.233 531.449C655.13 533.591 652.098 535.812 649.138 538.132Z" fill="#FFF7E9" />
      <path d="M552.004 409.771C544.833 409.395 539.307 403.241 539.683 396.054L539.878 392.328C539.946 391.043 541.039 390.054 542.321 390.122L577.625 391.972C578.906 392.039 579.891 393.136 579.824 394.422L579.628 398.147C579.252 405.335 573.112 410.877 565.942 410.501L552.004 409.771Z" fill="white" />
      <Heart />
    </svg>
  );
}
function Heart() {
  const [springs] = useSpring(() => ({
    from: {
      transform: 'rotate(0deg) scale(1)',
    },
    to: async (next, cancel) => {
      await next({ transform: 'rotate(3deg) scale(1.2)' });
      await next({ transform: 'rotate(-6deg) scale(0.8)' });
      await next({ transform: 'rotate(0deg) scale(1)' });
    },
    config: {
      friction: 15,
      duration: 200,
    },
    loop: true,
    delay: 1500,
  }));

  return (
    <animated.g style={{
      ...springs,
      transformOrigin: 'center',
      transformBox: 'fill-box',
    }}
    >
      <rect width="350" height="350" rx="50" transform="matrix(0.866025 0.5 -0.866025 0.5 314.109 17)" fill="none" stroke="none" />
      <path d="M316.103 28.1752C313.448 74.003 363.048 132.266 363.048 132.266C363.048 132.266 406.827 182.565 416.142 182.565C425.456 182.565 476.78 120.949 476.78 120.949C476.78 120.949 528.077 68.6691 513.34 27.4373C500.905 -7.35271 449.069 14.0243 412.323 59.7126C390.992 4.70334 323.648 -25.6163 316.103 28.1752Z" fill="#D85314" />

      <defs>
        <clipPath id="clip0_307_77">
          <path
            fill="#fff"
            d="M0 0H230V230H0z"
            transform="matrix(.86603 -.5 0 1 377.962 426)"
          />
        </clipPath>
        <clipPath id="clip1_307_77">
          <path
            fill="#fff"
            d="M0 0H250V250H0z"
            transform="matrix(.86595 .48687 -.0071 .99587 32.776 300)"
          />
        </clipPath>
      </defs>
    </animated.g>
  );
}
export default Pipo;
