import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useGlobal from '../global-state/store';

export default function GlobalSnackbar() {
  const [globalState, globalActions] = useGlobal();
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const [isNonClosable, setIsNonClosable] = useState(true);

  useEffect(() => {
    if (globalState.globalSnackbar.display) {
      setIsNonClosable(true);
      const timer = setTimeout(() => {
        setAutoHideDuration(15000);
        setIsNonClosable(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [globalState.globalSnackbar.display]);

  const handleClose = (event, reason) => {
    if (isNonClosable && reason === 'clickaway') {
      return;
    }
    globalActions.closeSnackbar();
  };

  return (
    <Snackbar
      open={globalState.globalSnackbar.display}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity={globalState.globalSnackbar.severity}
        sx={{ p: 5, boxShadow: 3 }}
        action={(
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        {globalState.globalSnackbar.message}
      </Alert>
    </Snackbar>
  );
}
