import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import seaview from '../files/images/gib.jpg';
import col1 from '../files/images/col1.jpg';
import col2 from '../files/images/col2.jpg';

export default function Background({ refToSwitch }) {
  const [currentImageBLock, setCurrentImageBlock] = useState(FirstImageBox);

  useEffect(() => {
    const handleScroll = () => {
      if (refToSwitch.current) {
        const rect = refToSwitch.current.getBoundingClientRect();
        if (rect.bottom <= window.innerHeight) {
          setCurrentImageBlock(SecondImageBox);
        } else {
          setCurrentImageBlock(FirstImageBox);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [refToSwitch]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: -1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {currentImageBLock}
    </Box>
  );
}

function FirstImageBox() {
  return (
    <Box
      component="img"
      src={seaview}
      alt="seaview"
      sx={{
        flex: 1,
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        objectPosition: 'center',
        transition: 'top 0.1s ease-out',
      }}
    />
  );
}

function SecondImageBox() {
  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '100vh',
      px: { xs: 0, md: 3 },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Box
        component="img"
        src={seaview}
        alt="seaview"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          zIndex: -1,
          mb: 3,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 1,
          width: '100%',
          height: { xs: '100%', md: '60%' },
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={col1}
          alt="col1"
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '50%', md: '100%' },
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Box
          component="img"
          src={col2}
          alt="col2"
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '50%', md: '100%' },
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
    </Box>
  );
}
