/* eslint-disable max-len */
import React from 'react';
import { useSpring, animated } from '@react-spring/web';

function Pach(props) {
  const { width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="100%"
      fill="none"
      viewBox="0 0 800 800"
      overflow="visible"
    >
      <path d="M752.93 467.725L800 456.592L792.725 425.903L754.834 434.862C754.834 434.35 754.859 433.861 754.859 433.348C754.859 373.851 738.405 319.628 709.595 273.412C733.717 189.134 688.598 78.8076 688.598 78.8076C688.598 78.8076 605.958 114.062 558.936 156.714C511.108 142.286 457.153 137.647 400 137.647C342.553 137.647 288.306 141.651 240.308 156.054C193.189 113.697 111.402 78.8076 111.402 78.8076C111.402 78.8076 66.2594 189.161 90.4047 273.437C61.6203 319.653 45.1172 373.876 45.1172 433.35C45.1172 433.862 45.1656 434.351 45.1656 434.864L7.275 425.905L0 456.592L47.0453 467.725C51.2203 504.565 62.1578 537.647 78.6609 566.822L20.1172 590.234L31.8109 619.531L96.1906 593.775C158.373 676.808 271.142 721.192 400 721.192C528.833 721.192 641.627 676.808 703.809 593.775L768.189 619.531L779.883 590.234L721.338 566.822C737.842 537.647 748.78 504.565 752.93 467.725Z" fill="black" />
      <Eyes />
    </svg>
  );
}
function Eyes() {
  const [springs] = useSpring(() => ({
    from: { r: 65 },
    to: async (next, cancel) => {
      await next({ r: 0, delay: 2000 });
      await next({ r: 65, delay: 200 });
      await next({ r: 65, delay: 3000 });
      await next({ r: 0 });
      await next({ r: 65, delay: 200 });
      await next({ r: 0, delay: 300 });
      await next({ r: 65, delay: 200 });
      await next({ r: 65, delay: 1000 });
    },
    config: {
      friction: 15,
      duration: 10,
    },
    loop: true,
  }));

  return (
    <animated.g style={{ transformOrigin: 'center', transformBox: 'fill-box' }}>
      <animated.circle cx="65" cy="65" style={{ r: springs.r }} transform="matrix(-1 0 0 1 355.706 325)" fill="#F4EB1E" />
      <rect width="170.95" height="60.4979" transform="matrix(-0.981627 -0.190809 -0.190809 0.981627 393.353 309.619)" fill="black" />
      <animated.circle cx="502.647" cy="390" style={{ r: springs.r }} fill="#F4EB1E" />
      <rect x="400" y="309.619" width="170.95" height="60.4979" transform="rotate(-11 400 309.619)" fill="black" />
    </animated.g>
  );
}
export default Pach;
