import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

function Counter() {
  const calculateTimeLeft = () => {
    const parisTime = new Date('2025-07-12T13:00:00Z').getTime() + (2 * 60 * 60 * 1000);
    const now = new Date().getTime();
    const difference = parisTime - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      p: 1,
    }}
    >
      {/* <Typography sx={{
        typography: {
          xs: 'body1',
          md: 'h6',
        },
        textAlign: 'center',
      }}
      >
        {'Dans '}
      </Typography> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
        <CounterPart time={timeLeft.days} label="jours" />
        <Separator />
        <CounterPart time={timeLeft.hours} label="heures" />
        <Separator />
        <CounterPart time={timeLeft.minutes} label="minutes" />
        <Separator />
        <CounterPart time={timeLeft.seconds} label="secondes" />
      </Box>
    </Box>
  );
}

function CounterPart({ time, label }) {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center',
    }}
    >
      <Typography sx={{ typography: { xs: 'body1', md: 'h6' } }}>{time}</Typography>
      <Typography sx={{ typography: { xs: 'body1', md: 'h6' } }}>{label}</Typography>
    </Box>
  );
}

function Separator() {
  return (
    <Typography sx={{ typography: { xs: 'body1', md: 'h6' } }}>:</Typography>
  );
}

export default Counter;
