/* eslint-disable max-len */
import React from 'react';

export default function Pipoheart({ size = 18, color }) {
  return (
    <svg width={size} height={size} fill={color} viewBox="0 0 716 654" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.368171 100.828C-9.1318 264.828 168.368 473.329 168.368 473.329C168.368 473.329 325.035 653.328 358.368 653.328C391.701 653.328 575.368 432.829 575.368 432.829C575.368 432.829 758.942 245.74 706.202 98.1875C661.702 -26.3125 476.202 50.1875 344.701 213.688C268.368 16.8314 27.3682 -91.6708 0.368171 100.828Z" fill="#D85314" />
    </svg>

  );
}
