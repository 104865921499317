import React from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { useFirestoreCollectionData, useFirestore } from 'reactfire';
import { collection, query, orderBy } from 'firebase/firestore';

export default function Songs() {
  const firestore = useFirestore();

  const personsRef = collection(firestore, 'spotifyPlaylist');
  const personsQuery = query(personsRef, orderBy('addedAt', 'desc'));

  const { data: persons } = useFirestoreCollectionData(personsQuery, { idField: 'id' });

  const columns: GridColDef[] = [
    { field: 'trackName', headerName: 'Nom', width: 200 },
    {
      field: 'addedAt',
      headerName: 'Date ajout',
      width: 175,
      type: 'dateTime',
      valueGetter: (value, row, column, apiRef) => row.addedAt.toDate(),
    },
    { field: 'id', headerName: 'ID', width: 150 },
  ];

  const getRowClassName = (params) => `batch-${params.row.batchId}`;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 3,
      width: '100%',
      textAlign: 'left',
      mt: 12,
    }}
    >
      <Box sx={{
        display: 'flex', flexDirection: 'column', height: 1000, gap: 3, width: '95%',
      }}
      >

        <Typography variant="h4">Les musiques à ajouter</Typography>
        <DataGrid
          rows={persons || []}
          columns={columns}
          getRowClassName={getRowClassName}
          slots={{ toolbar: GridToolbar }}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}
