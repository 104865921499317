import React, { useRef } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Home from './Home';
import LocalizationPage from './LocalizationPage';
import NotFound from './NotFound';
import PipoMenu from './Menu';
import Playlist from './playlist/Playlist';
import ReturnHomeButton from './ReturnHomeButton';
import GlobalSnackbar from './GlobalSnackbar';
import List from './list.js/List';
import Songs from './list.js/Songs';

export default function Router() {
  const rsvpSection = useRef(null);
  const contactSection = useRef(null);
  return (
    <BrowserRouter>
      <PipoMenu rsvpSection={rsvpSection} contactSection={contactSection} />
      <ReturnHomeButton />
      <GlobalSnackbar />

      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home rsvpSection={rsvpSection} contactSection={contactSection} />} />
        <Route
          path="localization"
          element={<LocalizationPage />}
        />
        <Route
          path="playlist"
          element={<Playlist />}
        />
        <Route
          path="secret/listedesinvitesdepipo0927"
          element={<List />}
        />
        <Route
          path="secret/listedessonsdepipo0927"
          element={<Songs />}
        />
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </BrowserRouter>
  );
}
