import React, { useState } from 'react';
import {
  Box, MenuItem, Menu, Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Pipo from '../files/icons/Pipo';

export default function PipoMenu({ rsvpSection, contactSection }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 30,
      behavior: 'smooth',
    });
  };

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleContactClick = async () => {
    if (location.pathname === '/' && contactSection) {
      scrollToRef(contactSection);
    } else if (contactSection) {
      navigate('/');
      await sleep(500);
      scrollToRef(contactSection);
    } else {
      navigate('/');
    }
    handleClose();
  };

  const handleRSVPClick = async () => {
    if (location.pathname === '/' && rsvpSection) {
      scrollToRef(rsvpSection);
    } else if (rsvpSection) {
      navigate('/');
      await sleep(500);
      scrollToRef(rsvpSection);
    } else {
      navigate('/');
    }
    handleClose();
  };

  const handleHomeClick = () => {
    navigate('/');
    handleClose();
  };

  const handlePlaylistClick = () => {
    navigate('playlist');
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: { xs: 0, md: 20 },
          right: { xs: 5, md: 30 },
          cursor: 'pointer',
          zIndex: 1000,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={handleClick}
      >
        <Pipo width={isLargeScreen ? '50' : '40'} />
        <Typography
          variant="caption"
        >
          MENU
        </Typography>
      </Box>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {location.pathname !== '/' && (
        <MenuItem onClick={handleHomeClick}>Accueil</MenuItem>
        )}
        <MenuItem onClick={handleRSVPClick}>RSVP</MenuItem>
        <MenuItem onClick={handlePlaylistClick}>Playlist</MenuItem>
        <MenuItem disabled onClick={handleClose}>Galerie</MenuItem>
        <MenuItem disabled onClick={() => {}}>Cagnotte</MenuItem>
        <MenuItem onClick={handleContactClick}>Contact</MenuItem>
      </Menu>
    </>
  );
}
