import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function LocalizationLink() {
  return (
    <Box
      sx={{
        py: 12,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'primary.main',
        px: 1,
        gap: 6,
      }}
    >
      <Typography sx={{
        typography: {
          xs: 'h6',
          md: 'h4',
        },
        color: 'background.default',
      }}
      >
        LOGEMENTS
      </Typography>
      <Typography sx={{
        typography: {
          xs: 'body1',
          md: 'h4',
        },
        color: 'background.default',
      }}
      >
        {'Nous contacter pour plus d’informations.\nQuelques places sont disponibles en dortoir.'}
      </Typography>
    </Box>
  );
}

export default LocalizationLink;
